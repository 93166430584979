@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.wrapperIcon {
  height: 48px;

  @include tablet{
    height: 68px;
  }

  img{
    height: 100%;
  }
}

.wrapperItem{
  border-radius: var(--border-radius-benefit-static-card);;
  background: var(--palette-gray-700);
  padding: 24px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperLink{
  transition: background-color 0.3s var(--transition-fn-smooth-in-out);
  &:hover{
    background-color: var(--palette-gray-600);
  }
}
