@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.wrapperItems {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 16px;

  .item {
    @include desktop {
      max-width: 31.5%;
      width: 100%;
    }
  }

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include desktop {
    display: flex;
    justify-content: center;
    row-gap: 30px;
    column-gap: 8px;
    flex-wrap: wrap;
  }

  @include large-desk {
    column-gap: 30px;
  }
}

.disclaimer {
  text-align: center;
  margin-top: 16px;
  @include tablet {
    margin-top: 30px;
  }
}

.wrapperItemsAfrica {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;

  .item {

    @include desktop{
      max-width: 360px;
    }
  }

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

}
